<template>
    <div class="container-fluid p-3">
        <div class="row flex-nowrap">
            <div class="width-300 mx-2 flex-shrink-0" v-if="categoryList.data">
                <div class="card py-2">
                    <div class="px-2 font-weight-bold h5">{{ categoryList.data.title }}</div>
                    <instruction-menu :item="item" :key="item.name" v-for="item in categoryList.data.children" />
                </div>
            </div>

            <div :key="instruction.name" class="flex-grow-1 mx-2" v-if="instruction">
                <div class="card">
                    <h1 class="h1 mb-0 pb-0 px-3 pt-4">{{ instruction.title }}</h1>

                    <MarkdownPreview :text="instruction.content" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MarkdownPreview from "@Platon/components/form/controls/MarkdownPreview.vue"
import InstructionMenu from "@Platon/components/instructions/InstructionMenu.vue"
import { searchOneBy } from "@Platon/core/helpers"

export default {
    name: "InstructionsPage",
    components: { InstructionMenu, MarkdownPreview },
    data() {
        return {
            category: this.$route.params.main,
            section: this.$route.params.sub,

            /** @type ?Instruction */
            instruction: null
        }
    },

    chimera: {
        categoryList() {
            let category = this.category

            if (!category || category === "_main") {
                category = ""
            } else {
                category += "/"
            }

            return {
                baseURL: this.$const.API_BASE_URL,
                url: `instructions/${category}`,
                on: {
                    success(result) {
                        if (result.data) {
                            this.findActiveSubcategory(result.data)
                        }
                    }
                }
            }
        }
    },

    provide() {
        return {
            instructions: this
        }
    },

    methods: {
        /**
         * @param item
         */
        openSubSection(item) {
            this.instruction = item
            this.section = item.name

            if (!this.category) {
                this.category = "_main"
            }

            this.$router.push({
                params: {
                    main: this.category,
                    sub: item.name
                }
            })
        },

        isActiveItem(item) {
            return item.name === this.section
        },

        /**
         * @param {Instruction} main
         */
        findActiveSubcategory(main) {
            let active = this.section

            let found = searchOneBy(main, (/* Instruction */ item) => {
                return item.name === active
            })

            if (found) {
                this.instruction = found
            }
        }
    },

    watch: {
        category(val) {
            this.$router.push({ params: { category: val } })
        }
    }
}
</script>
